body {
  margin: 0;
  // background-color: #fafafa;
}

h1 {
  font-size: 28px;
}

h1,
h2,
h3,
h4 {
  font-family: 'Open Sans', serif;
  /* font-family: "Lora", serif; */
}

h1 {
  font-size: 2.5em;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0em;
}

h2 {
  font-size: 2em;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0em;
}

h3 {
  font-size: 1.6em;
  font-weight: 350;
  line-height: 1.33;
  letter-spacing: 0em;
}

h4 {
  font-size: 1.3em;
  font-weight: 200;
  line-height: 1.33;
  letter-spacing: 0em;
}

h5 {
  font-weight: 300;
}

li {
  font-size: 0.8em;
  padding: 5px;
  font-family: 'Open Sans', serif;
}

nav {
  border-bottom: 1px solid #dcdcdc;
}

.scroll {
  overflow: auto;
  background: /* Shadow covers */ linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
      radial-gradient(
        50% 0,
        farthest-side,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ),
    radial-gradient(
        50% 100%,
        farthest-side,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background: /* Shadow covers */ linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

.trapezoid {
  border-top: 50px solid red;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  height: 0px;
}

/* button a {
      color: white;
      text-decoration: none;
    } */

button a:hover {
  color: white;
  text-decoration: none;
}

.btn-primary {
  color: #fff;
  background-color: #17438f;
  border-color: #17438f;
}

.btn-secondary a {
  color: white;
}

.auth {
  width: 500px;
  margin-top: 40px;
  margin-bottom: 40px;
  border: 1px solid #dedede;
  background-color: white;
  border-radius: 10px;
}

.logo-medium {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  display: block;
  max-width: 100px;
  height: auto;
}

.register-container {
  width: 80%;
}

.register-form {
  padding: 40px;
}

.register-tools-description {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  height: 100%;
  justify-content: center;
  color: #1a1a1a99;
}

.register-tools-description li {
  padding: 10px;
}

.vertical-separator {
  width: 3px;
  background-color: whitesmoke;
  display: block;
}

.register-action-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: end;
}

.nav-brand {
  line-height: 40px;
}

button.dropdown-item {
  color: black;
}

@media only screen and (max-width: 450px) {
  .auth {
    width: 100%;
  }

  .register-container {
    width: 100%;
  }

  .register-tools-description {
    padding: 10px;
  }
}

.auth.container {
  padding: 25px;
}

.auth form {
  padding: 30px;
}

.auth h1 {
  text-align: center;
  padding-bottom: 40px;
}

.auth button {
  width: 100%;
}

.auth img {
  margin: auto;
  margin-top: 10px;
  display: block;
  max-width: 35%;
  height: auto;
}

@media only screen and (min-width: 576px) {
  .modal-dialog {
    max-width: 750px;
  }
}
.container {
  margin-top: 25px;
  background-color: white;
  padding: 60px;
}

.footer {
  margin-top: 0px;
  background-color: transparent;
  color: rgb(208, 208, 208);
  align-items: flex-end;
  text-align: right;
  padding: 0px;
  font-size: 12px;
  padding-top: 5px;
}

.footer a {
  color: rgb(208, 208, 208);
}

.action-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link-container {
  border: 1px solid #bdbdbd;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
  padding: 0px 0px 0px 20px;
  height: 30px;
}

.link-container button {
  height: 28px;
  border-radius: 15px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-width: 0px;
  border-left-width: 1px;
  width: 25%;
}

.link-container p {
  height: 30px;
  line-height: 30px;
}

.showBaseline {
  float: right;
  z-index: 500;
}

.consulting-form {
  height: 100px;
}

.pillar-diagram {
  text-align: center;
}

.pillar li {
  padding: 0px;
}

.pillar-diagram h4 {
  padding-top: 10px;
  padding-bottom: 25px;
}
.pillar-diagram .col {
  padding: 10px;
}

.pillar-diagram ul {
  list-style: none;
  padding-left: 0;
}

.pillar-diagram .pillar {
  height: 100%;
  background-color: #f7f6f6;
  padding: 20px;
  border: 1px solid #ededed;
  border-radius: 5px;
}

.terminology {
  padding-left: 15px;
}

.navbar a {
  color: black;
}

.navbar a:hover {
  color: black;
}

.dashboardItem:hover {
  background-color: #3f84bf;
}

.dashboardItem:hover img {
  filter: invert(100%);
}

.dashboardItem {
  height: 100%;
  padding: 30px;
  border-radius: 10px;
  background-color: white;
  color: black;
  border-color: #2e7abb;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboardItem img {
  width: 25%;
}

.dashboardItem .contents {
  padding-top: 40px;
}

.percent-text {
  visibility: hidden;
}

.percent-value {
  font-size: 24px;
  text-align: center;
  line-height: 0;
  margin: 18px auto;
}

textarea.demo_input {
  height: 550px !important;
}

.heatmap {
  color: black;
}

.heatmap li {
  padding: 0px;
}

.heatmap th {
  background-color: #f7f7f7;
}

.heatmap--positive {
  background-color: #85bcea55;
}

.heatmap--warning {
  background-color: #ffc76455;
}

.heatmap--negative {
  background-color: #ffc764;
  font-weight: bold;
}

.heatmap--leftEdge {
  border-left-width: 5px;
  border-left-color: #ff7300;
  border-left-style: dotted;
}

.heatmap--bottomEdge {
  border-bottom-width: 5px;
  border-bottom-color: #ff7300;
  border-bottom-style: dotted;
}

.heatmap.table {
  width: 100%;
}

.indent {
  padding-left: 50px;
}

.excellent {
  color: green;
}

.good {
  color: green;
}

.moderate {
  color: orange;
}

.low {
  color: red;
}

.salesEffectivenessItem {
  height: 375px;
  padding: 20px;
  background-color: #f9f9f9;
}

.salesEffectivenessItem.disabled .card-body {
  opacity: 0.4;
  background-color: white;
  -webkit-filter: grayscale(1);
}

.link {
  width: 100%;
}

button a {
  color: white;
}

.step {
  position: absolute;
  left: -15px;
  top: -15px;
  background-color: #17438f;
  width: 50px;
  text-align: center;
  height: 50px;
  color: #ffffff;
  border-radius: 25px;
  display: flex;
  font-size: 1.2em;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.report_pillar {
}

.suggestion_title {
  text-transform: capitalize;
}

.competency-scatter {
  margin: auto;
  width: 75%;
}

.competency-table td {
  border: 1px solid #d8d8d8;
  padding: 0.3rem;
}

.competency-table th {
  border: 1px solid #d8d8d8;
  padding: 0.5rem;
  text-align: left;
  background-color: whitesmoke;
}

.competency-up {
  color: green;
  filter: drop-shadow(0px 0px 1px #787878);
}

.competency-right {
  color: yellow;
  filter: drop-shadow(0px 0px 1px #787878);
}

.competency-down {
  color: red;
  filter: drop-shadow(0px 0px 1px #787878);
}

.competency-table .percent-header {
}

.intro {
  text-align: center;
}

.survey__icon {
  display: block;
  text-align: center;
  margin: auto;
  width: 100px !important;
  height: 100px;
  background-color: #232d39;
  padding: 25px;
  border-radius: 50px;
  color: white;
}

.error__text {
  color: red;
}

.opportunity-container {
  display: flex;
}

.opportunity-details--form {
  width: 100%;
}

.opportunity-content {
  width: 50%;
}

.opportunity-preview {
  width: 50%;
  padding: 20px;
}

.edit-field--input {
  background-color: transparent;
  border: none;
}

.edit-field--icon {
  font-size: 0.8em;
  vertical-align: super;
}

.question-container {
  padding: 60px;
  margin: auto;
}

.question-title {
  text-align: center;
  padding: 0px 40px;
}

.question-footer {
  text-align: center;
}

.question-option {
  display: flex;
  justify-content: space-between;
}

.question-option:focus {
  outline: none;
  box-shadow: none;
}

.active_button {
  background-color: #bbb !important;
}

.question__page {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100vh;
  background-image: url('/survey_background.jpg');
  background-position: center;
  background-size: cover;
}

.question__page h2 {
  font-family: 'Roboto';
  font-weight: 100;
}

.question {
  padding: 40px 80px 40px 80px;
  background-color: #fdfdfd;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  max-width: 800px;
}

.question__title {
  padding-bottom: 10px;
  text-align: center;
  font-size: 1.5em;
}

.question__footer {
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #e0e0e0;
  display: flex;
  justify-content: space-between;
}

.btn:focus {
  box-shadow: none;
}

@media only screen and (max-width: 600px) {
  .question__page {
    display: block;
  }

  .question {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 90%;
    border-radius: 0px;
    padding: 40px 20px 40px 20px;
  }

  .question__title {
    font-size: 1.2em;
    padding-bottom: 10px;
  }

  .question__footer {
    height: 10%;
  }
}

.validation__error {
  float: right;
  color: red;
  font-style: italic;
  font-size: 0.8em;
}

.validation__hidden {
  float: right;
  visibility: hidden;
  color: red;
  font-style: italic;
  font-size: 0.8em;
}

.question__footer button {
  width: 100%;
  border-radius: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 1.2em;
  background-color: #325a84;
  border-width: 0px;
  border-color: #6f859c;
}

.question__footer button.left {
  border-bottom-left-radius: 10px;
  text-align: left;
  padding-left: 30px;
  border-right-width: 1px;
}

.question__footer button.right {
  border-bottom-right-radius: 10px;
  padding-right: 30px;
  text-align: right;
  border-left-width: 1px;
}

.rating__group {
  display: flex;
  flex-direction: column;
}

.rating {
  padding-top: 10px;
}

.rating__title {
  margin-bottom: 8px;
}

.rating__buttongroup {
  width: 100%;
}

.action__text {
  padding: 0px 20px 0px 20px;
}

.surveyProgress {
  padding-top: 10px;
  color: white;
  text-align: center;
}

.question-card--footer {
  display: flex;
  justify-content: space-between;
}

.column-test {
  // column-count: 2;
  // column-gap: 40px;
  // column-rule: 1px solid #eee;
}

.iframeContainer {
  background-image: url('/images/spinner.png');
  background-position: center;
  background-repeat: no-repeat;
}

.iframeContainer iframe {
  background: transparent;
}

.multi-page-question-container {
  height: 100%;
  scroll-behavior: smooth;
  overflow: auto;
}

.multi-page-question-container::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.boxShadowContainer:before {
  content: '';
  display: block;
  box-shadow: inset 0 0 10px black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#opportunitiesValuePieChart path:hover {
  cursor: pointer;
}

// Styles for NProgress
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031000;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
          animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

hr {
  margin: 40px 0px;
  border-top: 1px solid #f7f7f7;

}