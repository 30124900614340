@media print {
  header,
  footer,
  button,
  aside,
  nav,
  form,
  iframe,
  .menu,
  .hero,
  .adslot {
    display: none !important;
  }

  @page {
    size: A4 portrait;
    margin: 20mm;
  }

  body {
    /* width: 210mm !important; */
  }

  h1 {
    font-size: 32px !important;
  }

  h2 {
    font-size: 26px !important;
  }

  h3 {
    font-size: 24px !important;
  }

  h4 {
    font-size: 18px !important;
  }

  h6 {
    font-size: 13px !important;
  }

  p {
    font-size: 14px !important;
  }

  th {
    font-size: 13px !important;
  }

  .pillar-diagram img {
    width: 20px;
  }

  .pillar-diagram .pillar {
    padding: 10px;
  }

  .page-break {
    page-break-after: always;
  }

  #undercover-alert {
    display: none;
  }

  .watermark {
    display: absolute;
    position: fixed !important;
    opacity: 0.2;
    font-size: 20em;

    /* text-align: center; */
    /* vertical-align: center; */
    z-index: 1000;
    top: 40%;
    left: 3%;
    transform: rotate(45deg);
    transform-origin: center center;
  }
}
